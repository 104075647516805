// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".breadcrumbs_Boshr{display:flex;font-size:12px;font-weight:500;list-style:none;margin:0;padding:0;align-items:center;flex-wrap:wrap;color:#cfd8dc}.breadcrumbs__mobile_3pfyF svg{width:24px;height:24px}.breadcrumbs__mobile_3pfyF svg path{fill:#fff}.breadcrumbs__item_YfHdu{display:flex;align-items:center;margin-bottom:8px}.breadcrumbs__item_YfHdu:not(:first-child)::before{content:\"/\";color:#cfd8dc;display:inline-block;margin:0 8px}.breadcrumbs_Boshr span.breadcrumbs__item_YfHdu{color:#ffb200}.breadcrumbs__seo_cBz6j{margin:24px 16px 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbs": "breadcrumbs_Boshr",
	"breadcrumbs__mobile": "breadcrumbs__mobile_3pfyF",
	"breadcrumbs__item": "breadcrumbs__item_YfHdu",
	"breadcrumbs__seo": "breadcrumbs__seo_cBz6j"
};
module.exports = ___CSS_LOADER_EXPORT___;
