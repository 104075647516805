<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Breadcrumbs',

  props: {
    list: {
      type: Array,
      required: true
    }
  },

  jsonld () {
    const items = this.items.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: item.title,
      ...(item.to && { item: this.$config.WWW_HOST + item.to })
    }))

    return {
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: items
    }
  },

  computed: {
    ...mapGetters('viewport', ['isHeaderMobile']),

    items () {
      return [
        {
          title: 'Gaming-Goods',
          to: this.localeLocation('/')
        },
        ...this.list.map((item) => {
          let to = this.localeLocation(item.to)
          to = item.to ? to : this.$route.path
          return {
            ...item,
            to
          }
        })
      ]
    }
  },

  methods: {
    onBackClick () {
      this.$router.go(-1)
    },

    setTag (item) {
      return item.to ? 'NuxtLink' : 'span'
    }
  }
}
</script>

<template>
  <div v-if="list.length">
    <div :class="[$style.breadcrumbs, $style.breadcrumbs__seo]">
      <component :is="setTag(item)" v-for="(item, i) in items" :key="i" :to="item.to || null" :class="$style.breadcrumbs__item">
        {{ item.title }}
      </component>
    </div>
  </div>
</template>

<style lang="scss" module>
.breadcrumbs {
  display: flex;
  font-size: 12px;
  font-weight: 500;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
  flex-wrap: wrap;
  color: $blue-grey05;

  &__mobile {
    svg {
      width: 24px;
      height: 24px;

      path {
        fill: #fff;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &:not(:first-child) {
      &::before {
        content: "/";
        color: $blue-grey05;
        display: inline-block;
        margin: 0 8px;
      }
    }
  }

  span.breadcrumbs__item {
    color: #ffb200;
  }

  &__seo {
    margin: 24px 16px 0;
  }
}
</style>
